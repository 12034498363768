<template>
  <div v-swiper:Gallery="gallery" style="overflow:inherit; height:100vh;">
    <div class="swiper-wrapper">
      <div class="swiper-slide relative">
        <div class="absolute bottom-32 z-30 px-12 lg:flex justify-between w-full">
          <div class="w-7/12 pb-8">
            <p class="mb-4 text-5xl font-menu text-white font-medium">Pro Flow Liners with tricky sticky <br>Limestone</p>
            <p>Limestone which is a key ingredient in the cement industry, can also prove tricky to handle, conveyor and contain, but with design considerations from the outset there are many ways to make life easier when dealing with this material.</p>
          </div>
          <div>
            <a href="https://conveyortek.com/case-studies/view/3" class="py-8 px-10 absolute -bottom-11 right-10 inline-block bg-white text-gray max-h-2">View Case Study</a>
          </div>
        </div>
        <div class="bg-gray w-full h-full absolute z-10 opacity-50">
        </div>
        <img class="absolute h-full w-full object-cover" src="/app/themes/conveyortek/assets/images/cs-1.jpeg" alt="image">
      </div>
      <div class="swiper-slide relative">
          <div class="absolute bottom-32 z-30 px-12 lg:flex justify-between w-full">
            <div class="w-7/12 pb-8">
              <p class="mb-4 text-5xl font-menu text-white font-medium">McKinstrys Choose Conveyortek</p>
              <p>The biomass operation, part of the multi-award winning McKinstry Group, has gone from strength to strength since it was first established, with clients throughout the island of Ireland, the UK and farther afield.</p>
            </div>
            <div>
              <a href="https://conveyortek.com/case-studies/view/2" class="py-8 px-10 absolute -bottom-11 right-10 inline-block bg-white text-gray max-h-2">View Case Study</a>
            </div>
          </div>
        <div class="bg-gray w-full h-full absolute z-10 opacity-50">
        </div>
        <img class="absolute h-full w-full object-cover" src="/app/themes/conveyortek/assets/images/cs2.jpeg" alt="image">
      </div>
      <div class="swiper-slide relative">
          <div class="absolute bottom-32 z-30 px-12 lg:flex justify-between w-full">
            <div class="w-7/12 pb-8">
              <p class="mb-4 text-5xl font-menu text-white font-medium">Fast Delivery of Modular Conveyor Systems for the Recycling Industry</p>
              <p>Conveyortek’s successful delivery of 8 conveyor systems to integrate into an existing recycling system in Tralee Co Kerry within 2 weeks has allowed their Dillon Waste to increase production to keep up with demand.</p>
            </div>
            <div>
              <a href="https://conveyortek.com/case-studies/view/1" class="py-8 px-10 absolute -bottom-11 right-10 inline-block bg-white text-gray max-h-2">View Case Study</a>
            </div>
          </div>
          <div class="bg-gray w-full h-full absolute z-10 opacity-50">
        </div>
        <img class="absolute h-full w-full object-cover" src="/app/themes/conveyortek/assets/images/cs3.jpeg" alt="image">
      </div>
    </div>
    <div class="absolute text-left h-36 pl-12 pt-8 top-0 gallery-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'GallerySlider',
  data () {
    return {
      gallery: {
        cssMode: true,
        preventClicks: false,
        pagination: {
          clickable: true,
          el: '.gallery-pagination',
          renderBullet: function (index, className) {

            return '<span class=\"' + className + '\">' + 0 + + (index + 1) + '</span>';

          }
        },
        slidesPerView: 1,
        spaceBetween: 0,
      }
    }
  }
}
</script>
