<template>
  <div id="nav">
    <div id="sub-menu" v-if="selected==1 || selected==2 || selected==3" class="fixed transition ease-in-out duration-150 text-white z-30 bg-gray-600 w-full h-screen">
      <a href="#" @click="selected = 0" class="absolute z-30 top-8 left-10 transition ease-in-out duration-300 transform hover:rotate-90">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </a>
      <div class="mx-auto px-4 sm:px-8 lg:px-12 py-8">
        <div class="w-full relative flex items-center justify-between">
          <div class="hidden lg:flex justify-end items-center md:w-full font-menu text-xl">
            <ul id="menu-main-menu" class="navigation space-x-8 flex mr-8">
              <li id="menu-item-41" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-41"><a href="#" class="flex gap-x-2" @click="selected = 1">Our Company <icon-down class="text-white"></icon-down></a></li>
              <li id="menu-item-42" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-42"><a href="#" class="flex gap-x-2" @click="selected = 2">What We Do <icon-down class="text-white"></icon-down></a></li>
              <li id="menu-item-43" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-43"><a href="#" class="flex gap-x-2" @click="selected = 3">Products <icon-down class="text-white"></icon-down></a></li>
            </ul>
            <slot />
            <a href="https://conveyortek.com/"><icon-home class="text-white w-6 -mt-1"></icon-home></a>
          </div>
           <div class="w-24 flex justify-end items-center -mt-1">
            <slot name="icons-right" />
          </div>
          <div v-if="!isOpen" class="flex-shrink-0 lg:hidden">
            <button ref="openButton" type="button" class="block focus:outline-none" aria-label="Menu" @click="open">
              <svg class="h-6 w-6" fill="black" viewBox="0 0 24 24">
                <path d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" />
                <path d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" />
                <path d="M4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H4Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="lg:grid lg:grid-cols-2">
        <div class="pl-12">
          <p v-if="selected==1" class="text-5xl text-blue font-bold font-menu">Our Company</p>
          <p v-if="selected==2" class="text-5xl text-blue font-bold font-menu">WHAT WE DO</p>
          <p v-if="selected==3" class="text-5xl text-blue font-bold font-menu">Products</p>
          <hr class="border-gray-400 my-4 w-7/12">
          <ul v-if="selected==1" class="space-y-5">
            <li><a href="https://conveyortek.com/about" class="text-3xl font-bold font-menu">About</a></li>
            <li><a href="https://conveyortek.com/careers" class="text-3xl font-bold font-menu">Careers</a></li>
          </ul>
          <ul v-if="selected==2" class="space-y-5">
            <li><a href="https://conveyortek.com/innovation" class="text-3xl font-bold font-menu">Innovation</a></li>
            <li><a href="https://conveyortek.com/conveyor-systems" class="text-3xl font-bold font-menu">Conveyor Systems</a></li>
            <li><a href="https://conveyortek.com/metal-separation" class="text-3xl font-bold font-menu">Metal Separation</a></li>
            <li><a href="https://conveyortek.com/case-studies" class="text-3xl font-bold font-menu">Case Studies</a></li>
          </ul>
          <ul v-if="selected==3" class="space-y-5">
            <li><a href="https://conveyortek.com/products" class="text-3xl font-bold font-menu">View All</a></li>
            <li><a href="https://conveyortek.com/products?type=innovation" class="text-3xl font-bold font-menu">Innovation</a></li>
            <li><a href="https://conveyortek.com/products?type=conveyor-systems" class="text-3xl font-bold font-menu">Conveyor Systems</a></li>
            <li><a href="https://conveyortek.com/products?type=metal-separation" class="text-3xl font-bold font-menu">Metal Separation</a></li>
            <li><a href="https://conveyortek.com/products?type=accessories" class="text-3xl font-bold font-menu">Conveyor Accessories</a></li>
            <hr class="my-4 border-white w-7/12 opacity-30">
            <li><a href="https://conveyortek.com/products/service-and-aftersales" class="text-3xl font-bold font-menu">Service & Aftersales</a></li>
          </ul>
        </div>
        <div class="h-screen">
          <gallery-slider></gallery-slider>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-8 lg:px-12 py-4">
      <div class="w-full relative flex items-center justify-between">
        <div class="relative lg:mb-0">
          <slot name="brand" />
        </div>
        <div class="hidden lg:flex justify-end items-center md:w-full font-menu text-xl">
          <ul id="menu-main-menu" class="navigation space-x-8 flex mr-8">
            <li id="menu-item-41" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-41"><a href="#" class="flex gap-x-2" @click="selected = 1">Our Company <icon-down class="text-black"></icon-down></a></li>
            <li id="menu-item-42" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-42"><a href="#" class="flex gap-x-2" @click="selected = 2">What We Do <icon-down class="text-black"></icon-down></a></li>
            <li id="menu-item-43" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-43"><a href="#" class="flex gap-x-2" @click="selected = 3">Products <icon-down class="text-black"></icon-down></a></li>
          </ul>
          <slot />
          
        </div>
         <div class="w-24 flex justify-end ml-auto items-center -mt-1">
          <slot name="icons-right" />
        </div>
        <div v-if="!isOpen" class="flex-shrink-0 lg:hidden">
          <button ref="openButton" type="button" class="block focus:outline-none" aria-label="Menu" @click="open">
            <svg class="h-6 w-6" fill="black" viewBox="0 0 24 24">
              <path d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" />
              <path d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" />
              <path d="M4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H4Z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="lg:hidden">
      <!-- Off-canvas menu background overlay -->
      <transition
        enter-class="opacity-0"
        enter-active-class="ease-out transition-medium"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-active-class="ease-out transition-medium"
        leave-to-class="opacity-0"
        appear
      >
        <div v-show="isOpen" class="z-20 fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-black opacity-50" />
        </div>
      </transition>
      <!-- Off-canvas menu -->
      <transition
        enter-class="translate-y-full"
        enter-active-class="ease-out transition-slow"
        enter-to-class="translate-y-0"
        leave-class="translate-y-0"
        leave-active-class="ease-in transition-medium"
        leave-to-class="translate-y-full"
        appear
      >
        <div v-show="isOpen" class="z-20 fixed inset-0 transition-transform">
          <div class="relative h-full flex flex-col">
            <div class="bg-gray text-white h-14 flex justify-start px-6 lg:px-8 py-6 w-full" aria-label="Close" tabindex="-1" @click="close">
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" @click="selected = 0">
                <path d="M18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L13.4142 12L19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L12 10.5858L5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L10.5858 12L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L12 13.4142L18.2929 19.7071Z" />
              </svg>
            </div>
            <div class="bg-gray h-full z-20 relative text-white overflow-y-auto overflow-x-hidden">
              <div class="">
                <slot name="mobile-menu" />
                 <ul class="px-4">
                   <li class="mobile-item"><a href="https://conveyortek.com/">Home</a></li>
                   <li class="mobile-item"><a href="#" class="flex gap-x-2" @click="selected = 4">Our Company <icon-down class="text-white"></icon-down></a></li>
                   <li class="mobile-item"><a href="#" class="flex gap-x-2" @click="selected = 5">What We Do <icon-down class="text-white"></icon-down></a></li>
                   <li class="mobile-item"><a href="#" class="flex gap-x-2" @click="selected = 6">Products <icon-down class="text-white"></icon-down></a></li>
                   <li class="mobile-item"><a href="/">Shop</a></li>
                   <li class="mobile-item"><a href="https://conveyortek.com/resources">Resources</a></li>
                   <li class="mobile-item"><a href="https://conveyortek.com/news">News</a></li>
                   <li class="mobile-item"><a href="https://conveyortek.com/events">Events</a></li>
                   <hr>
                   <li class="mobile-item"><a href="https://conveyortek.com/contact">Contact</a></li>
                   <li class="mobile-item"><a href="#">Cart</a></li>
                   <li class="mobile-item"><a href="#">My Account</a></li>
                 </ul>
                 <div class="absolute px-12 py-12 left-8 top-12 bg-gray-800 w-full h-full" v-if="selected==4 || selected==5 || selected==6">
                   <a href="#" @click="selected = 0" class="mb-4 flex items-center gap-x-2 text-xl"><icon-down class="text-white transform rotate-90"></icon-down> Back</a>
                   <p v-if="selected==4" class="text-4xl text-blue font-bold font-menu">Our Company</p>
                   <p v-if="selected==5" class="text-4xl text-blue font-bold font-menu">WHAT WE DO</p>
                   <p v-if="selected==6" class="text-4xl text-blue font-bold font-menu">Products</p>
                   <hr class="border-gray-400 my-4 w-7/12">
                   <ul v-if="selected==4" class="space-y-5">
                     <li><a href="https://conveyortek.com/about" class="mobile-item">About</a></li>
                     <li><a href="https://conveyortek.com/careers" class="mobile-item">Careers</a></li>
                   </ul>
                   <ul v-if="selected==5" class="space-y-5">
                     <li><a href="https://conveyortek.com/innovation" class="mobile-item">Innovation</a></li>
                     <li><a href="https://conveyortek.com/conveyor-systems" class="mobile-item">Conveyor Systems</a></li>
                     <li><a href="https://conveyortek.com/metal-separation" class="mobile-item">Metal Separation</a></li>
                     <li><a href="https://conveyortek.com/case-studies" class="mobile-item">Case Studies</a></li>
                   </ul>
                   <ul v-if="selected==6" class="space-y-5">
                     <li><a href="https://conveyortek.com/products" class="mobile-item">View All</a></li>
                     <li><a href="https://conveyortek.com/products?type=innovation" class="mobile-item">Innovation</a></li>
                     <li><a href="https://conveyortek.com/products?type=conveyor-systems" class="mobile-item">Conveyor Systems</a></li>
                     <li><a href="https://conveyortek.com/products?type=metal-separation" class="mobile-item">Metal Separation</a></li>
                     <li><a href="https://conveyortek.com/products?type=accessories" class="mobile-item">Conveyor Accessories</a></li>
                     <hr class="my-4 border-white w-7/12 opacity-30">
                     <li><a href="https://conveyortek.com/products/service-and-aftersales" class="mobile-item">Service & Aftersales</a></li>
                   </ul>
                 </div>
              </div>

            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      seen: false,
      selected: undefined,
    }
  },
  methods: {
    open () {
      this.isOpen = true
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    close () {
      this.isOpen = false
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    myFilter: function() {
        this.isActive = !this.isActive;
        // some code to filter users
      }
  }
}
</script>
<style lang="scss">
.ease-linear {
  transition-timing-function: linear
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.transition-fastest {
  transition-duration: 75ms
}

.transition-faster {
  transition-duration: 100ms
}

.transition-fast {
  transition-duration: 150ms
}

.transition-medium {
  transition-duration: 200ms
}

.transition-slow {
  transition-duration: 300ms
}

.transition-slower {
  transition-duration: 500ms
}

.transition-slowest {
  transition-duration: 700ms
}

.transition-all {
  transition-property: all
}

.transition-opacity {
  transition-property: opacity
}

.transition-transform {
  transition-property: transform
}

.translate-y-full {
  transform: translateY(100%);
}
.-translate-y-full {
  transform: translateY(-100%);
}
.translate-y-0 {
  transform: translateY(0);
}

.scale-90 {
  transform: scale(.9)
}

.scale-100 {
  transform: scale(1)
}

.origin-top {
  transform-origin: top
}

.origin-top-right {
  transform-origin: top right
}

.origin-right {
  transform-origin: right
}

.origin-bottom-right {
  transform-origin: bottom right
}

.origin-bottom {
  transform-origin: bottom
}

.origin-bottom-left {
  transform-origin: bottom left
}

.origin-left {
  transform-origin: left
}

.origin-top-left {
  transform-origin: top left
}
</style>
