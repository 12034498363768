<template>
  <div v-swiper:TestimonialSlider="settings" style="overflow:inherit">
    <div class="mb-6 space-x-2 hidden lg:block">
      <div class="testimonial-prev outline-none w-auto inline-flex">
        <svg class="text-teal-500 w-6 h-6 outline-none transition-colors" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
        </svg>
      </div>
      <div class="testimonial-next outline-none w-auto inline-flex">
        <svg class="text-teal-500 w-6 h-6 transition-colors" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </div>
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="testimonial-pagination swiper-pagination text-left"></div>
  </div>
</template>
<script>
export default {
  name: 'TestimonialSlider',
  data () {
    return {
      settings: {
        cssMode: true,
        preventClicks: false,
        // loop: true,
        navigation: {
         nextEl: '.testimonial-next',
         prevEl: '.testimonial-prev',
        },
        preventClicks: false,
        pagination: {
          clickable: true,
          el: '.testimonial-pagination'
        },
        spaceBetween: 0,
        slidesPerView: 1
      }
    }
  }
}
</script>
