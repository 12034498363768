window.Vue = require('vue')
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import axios from 'axios'

import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'

import ContactForm from './components/ContactForm'
import ContactModal from './components/ContactModal'
import ProductSlider from './components/ProductSlider'
import GallerySlider from './components/GallerySlider'
import CookieConsent from './components/CookieConsent'
import AppFixedHeader from './components/AppFixedHeader'
import TestimonialSlider from './components/TestimonialSlider'
import AppNavBar from './components/AppNavBar'

import CherryvalePlastics from './components/CherryvalePlastics'
import ShieldOfFaith from './components/ShieldOfFaith'

Vue.component('ContactModal', ContactModal)
Vue.component('ContactForm', ContactForm)
Vue.component('CookieConsent', CookieConsent)
Vue.component('ProductSlider', ProductSlider)
Vue.component('GallerySlider', GallerySlider)
Vue.component('TestimonialSlider', TestimonialSlider)
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)

Vue.component('CherryvalePlastics', CherryvalePlastics)
Vue.component('ShieldOfFaith', ShieldOfFaith)

const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})

extend('password', {
  validate (value, { target }) {
    return value === target
  },
  params: ['target'],
  message: 'Password confirmation does not match'
})
Vue.prototype.$axios = axios
Vue.config.productionTip = false
