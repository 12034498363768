<template>
  <div>
    <button id="show-modal" class="text-sm lg:text-xl border inline-block border-blue text-center text-blue font-bold block w-full uppercase py-4 transition ease-in-out duration-150 hover:bg-blue hover:text-white" @click="showModal = true">Request A Delivery Quotation</button>

      <div class="fixed w-screen flex overflow-y-scroll h-screen z-20 left-0  top-0"v-if="showModal" @close="showModal = false">
        <div class="absolute opacity-25 bg-black w-full h-full" @click="showModal = false">
        </div>
        <div class="relative bg-white py-8 px-4 lg:px-16 mx-auto my-auto opacity-100">
          <a href="javascript:void" @click="showModal = false" class="group bg-white text-blue absolute ml-auto right-4 top-4 z-20 ">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 transition ease-in-out duration-300 group-hover:rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
          </a>
          <p class="font-bold uppercase text-center text-blue mb-4 text-xl pt-6 lg:text-3xl">Request A Delivery Quotation</p>
          <contact-form></contact-form>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showModal: false,
    }
  },
}
</script>
